@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--colorFail);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.imagePreviews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.imagePreview {
  position: relative;
  width: 80px;
  height: 80px;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.removeImageButton {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addImagesWrapper {
  margin-bottom: 10px;
  margin-top: -10px;
}

.addImagesButton {
  display: inline-block;
  padding: 8px 12px;
  background-color: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.imageInput {
  display: none; /* Hidden input */
}
