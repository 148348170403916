@import '../../styles/customMediaQueries.css';

.root {
}

/* reviews dropdown starts */
.dropdownContainer {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdownLabel {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.dropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: border-color 0.3s ease;
}
.dropdown:hover,
.dropdown:focus {
  border-color: #888;
}
.errorText {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
.sectionReviews {
  padding: 20px;
}
.sectionHeadingWithExtraMargin {
  margin-bottom: 20px;
}
.showMoreButton {
  background: transparent;
  border: none;
  color: rgb(50, 50, 50);
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.showMoreButton:hover {
  text-decoration: none;
  color: var(--marketplaceColor);;
}

.showreviews,
.hidereviews {
  display: inline-block;
  font-size: 1.2em;
  transition: transform 0.2s ease-in-out;
  padding-left: 3px;
}
.showreviews,
.hidereviews {
  animation: bounceArrow 0.6s infinite alternate;
}
@keyframes bounceArrow {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(3px);
  }
}

.buttonWrapper{
  text-align: center;
}
.truncated, .expanded {
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s ease-out;
}

.truncated p, .expanded p {
  margin: 0px;
}

.truncated::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
}

.expanded::after {
  display: none;
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 16px;
  font-size: 1em;
  margin-top: 0.5em;
  transition: all 0.3s ease;
}

/* Mobile styles */
@media (max-width: 768px) {
  .toggleButton {
    /* background-color: rgb(241, 241, 238); */
    border: 1px solid black;
    border-radius: 24px;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .toggleButton:hover {
    background-color: #f0f0f0;
    border: 1px solid black;
    border-radius: 24px;
  }
}
.pageRoot {
  padding-bottom: 90px;
  min-height: 0;
  min-width: 0;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.hrclass{
  width: 100%;
  color: pink; 
  border-style:solid;
  @media (max-width: 768px) {
    width: 90%;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  min-height: 0;
  min-width: 0;
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  /* margin-bottom: 36px; */

  @media (--viewportMedium) {
    padding: 0;
    /* margin-bottom: 40px; */
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    /* padding: 5px 0 2px 0; */
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: 240px;
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 240px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;
  display: none;
  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.deliverymethodbox, .additionalallergensbox{
  @media (max-width: 760px) {
  padding: 24px !important;
}
}
